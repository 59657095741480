<template>
  <div class="page-step">
    
    <h3 class="title">成交阶段</h3>

    <div class="box-index sec">
      <el-progress type="dashboard"
        color="#377EEA"
        :stroke-width="10"
        :format="p => `${p}`"
        :percentage="score"></el-progress>
      <div id="myChart" class="chart">暂无历史数据</div>
      
    </div>
    <div class="sec" style="padding: 0;">
      <div class="box-step">
          <span v-for="(item,idx) in steps" :key="idx"
             :class="item.checked > 0 ? 'sel' : ''"
             @click="onChangeTab(item.id)"
                ><strong>{{item.title}}</strong></span>
      </div>
      <div class="box-advise">
        <div class="level">当前成交阶段：{{detail.barriers_level}}</div>
        <div class="text"><span style="color: #ED5A54">*</span> 指导建议：{{detail.deal_advise}}</div>
      </div>
    </div>
    <div v-if="detail.user_level" class="sec"
      style="padding: 0.5rem; display: flex; justify-content: space-between; font-size: 0.7rem;">
      <span>客户级别</span>
      <span style="color: #377EEA;">{{detail.user_level}}级</span>
    </div>
    <h3 class="title">成交障碍点内容</h3>
    <div class="box-barrier sec" v-for="(item,idx) in barriers" :key="idx">
      <div class="tit">{{item.name}}</div>
      <div class="list">
        <span v-for="(l,i) in item.list" :class="l.checked ? 'sel' : ''" :key="i">{{l.text}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { postForm } from '@/api'
import * as echarts from 'echarts'

export default {
  data() {
      return {
        score: 0,
        curStep: '明确需求',
        steps: [],
        barriers: [],
        indexList: [],
        detail: {},
      }
  },
  components: {
  },

  methods: {
    initChart(indexList) {
      this.myChart.setOption({
        grid: {
          top: "40px",
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#eee",
            },
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          type: "category",
          data: indexList,
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          type: "value",
          axisTick: {
            show: false,
          },
        },
        dataZoom: [
          {
            type: 'slider',
            maxValueSpan: 4,
            height: '10px',
          },
        ],
        series: [
          {
            data: indexList,
            markPoint: {
              data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
            },
            type: "line",
            smooth: true,
            symbolSize : 10, // 拐点圆的大小
            lineStyle: {
              width: 3.5,
            },
            label : {
              show: true,
              textStyle: {
                color: "#fff",
                backgroundColor: "#377EEA",
                borderRadius: 3,
                padding: [2,5,2,5],
              }
            },
            itemStyle: {
              color: {
                type: 'linear',
                colorStops: [{offset: 0, color: "#3CB07A"},{offset: 1, color: "#377EEA"}],
              },
              width: 8,
            }
          },
        ],
     });
    }
  },
  mounted() {
    const {id} = this.$router.currentRoute.query || {};
    postForm('/h5/seller/getDealStageData', {id}).then(res => {
        if (res.status === 1) {
          const {barriers, track_details, index, ...rest} = res.data;
          this.detail = rest;
          this.barriers = barriers;
          this.score = index;
          this.indexList = (track_details || []).map(o => Number(o.index));
          if (this.indexList.length > 1) {
            this.myChart =echarts.init(document.getElementById("myChart"));
            this.myChart.clear();
            this.initChart(this.indexList);
          }
          this.steps = barriers.map(o => {
            const check = (o.list || []).filter(l => (l.checked == 1));
            return {title: o.name, id: o.name, checked: check.length};
          });
        } else {
          this.$message.error(res.msg);
        }
      });
  }
  
}
</script>

<style lang="less">

.page-step {
  position: relative;
  padding-bottom: 1rem;

  h3.title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .chart {
    width: 100%;
    height: 150px;
    line-height: 150px;
    font-size: 1rem;
    color: #eee;
  }

  .box-index {
    padding: 0.8rem 0;
    text-align: center;

    .el-progress__text {
      font-size: 1.8rem !important;
      color: #377EEA;
      font-weight: bold;
      &:after {
        content: '成交指数';
        font-size: 0.6rem;
        color: #969799;
        display: block;
        margin-top: 0.2rem;
        font-weight: 400;
      }
    }
  }

  .box-advise {
    border-top: 1px solid #efefef;
    padding: 0.5rem;
    font-size: 0.6rem;
    line-height: 1.5;
    .level {
      font-size: 0.7rem;
      font-weight: 500;
      color: #377EEA;
      margin: 0.5rem 0;
    }
  }
  .box-barrier {
    padding: 0.8rem;
    .tit {
      font-size: 0.7rem;
      font-weight: 500;
    }
    .list {
      span {
        display: inline-block;
        background: #F5F5F5;
        margin-right: 0.5rem;
        margin-top: 0.5rem; 
        border-radius: 4px;
        padding: 1px 5px;
        border: 1px solid #F5F5F5;

        &.sel {
          background: #fff;
          border: 1px solid #377EEA;
          color: #377EEA;
        }
      }
    }
  }
  
  .box-step {
    position: relative;
    width: 100%;
    display: flex;
    padding: 0.5rem 0;

    &:before {
      content: ' ';
      border-bottom: 1px dashed #000;
      opacity: 0.12;
      height: 1.3rem;
      position: absolute;
      left: 1rem;
      right: 1rem;
    }
    span {
      font-size: 0.5rem;
      color: #C8C9CC;
      flex: 1;
      text-align: center;
      padding-top: 2.7rem;
      background-size: auto 2.5rem;
      background-position: center top;
      background-repeat: no-repeat;
      margin: 0;
      z-index: 1;

      strong {
        width: 1.2rem;
        display: inline-block;
      }
      &:nth-child(1) {
        background-image: url('~@/assets/step/1-1.png');
      }
      &:nth-child(2) {
        background-image: url('~@/assets/step/2-1.png');
      }
      &:nth-child(3) {
        background-image: url('~@/assets/step/3-1.png');
      }
      &:nth-child(4) {
        background-image: url('~@/assets/step/4-1.png');
      }
      &:nth-child(5) {
        background-image: url('~@/assets/step/5-1.png');
      }

      &.sel {
        color: #377EEA;
      }
      &:nth-child(1).sel {
        background-image: url('~@/assets/step/1.png');
      }
      &:nth-child(2).sel {
        background-image: url('~@/assets/step/2.png');
      }
      &:nth-child(3).sel {
        background-image: url('~@/assets/step/3.png');
      }
      &:nth-child(4).sel {
        background-image: url('~@/assets/step/4.png');
      }
      &:nth-child(5).sel {
        background-image: url('~@/assets/step/5.png');
      }
    }
  }
}

</style>
