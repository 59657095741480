<template>
  <div class="page-count bg-main" @click="selPop={}">
    <div class="box-top">
      <div class="fixed">
        <div class="box-tab">
          <p>
          <el-select v-model="ruleForm.date_type" placeholder="请选择" @change="changeDate">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item"
              :value="item">
            </el-option>
            </el-select>
          </p>
          <p>
            <img v-if="true"
            class="icon" src="@/assets/i_date.png"/>
            <img v-else class="icon" src="@/assets/i_date_sel.png"/>
            <el-date-picker
              v-model="ruleForm.start_date"
              type="date"
              align="center"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              :picker-options="startOpts"
              format="yy.M.dd"
              @change="loadData"
              placeholder="开始日期">
            </el-date-picker>
            <span>-</span>
            <el-date-picker
              v-model="ruleForm.end_date"
              type="date"
              align="center"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              format="yy.M.dd"
              :picker-options="endOpts"
              @change="loadData"
              placeholder="结束日期">
            </el-date-picker>
          </p>
        </div>
      </div>
    </div>

    <h3 class="title">
      <strong>接待总览</strong>
      <span>
          <el-radio-group v-model="inNum.r1" @change="getTotal">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="box-result sec">
      <div class="score" v-for="(item, idx) in totalAccount" :key="idx">
        <div class="num"><strong>{{item.value}}</strong></div>
        <div class="name">{{item.title}}({{item.unit}})</div>
      </div>
    </div>


    <h3 class="title">
      <strong>检核分析</strong>
      <span>
          <el-radio-group v-model="inNum.r2" @change="getChart">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec">
      <div id="chart" style="width: 100%; height: 300px;padding-top: 0.5rem;"></div>
    </div>

    <h3 class="title">
      <strong>话术标签</strong>
      <span>
          <el-radio-group v-model="inNum.r4" @change="getLabel">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec">
      <div class="box-tag">
        <span :class="tagTab == 1 ? 'sel' : ''" @click="changeTagTab(1)">
          开口率
        </span>
        <span :class="tagTab == 2 ? 'sel' : ''" @click="changeTagTab(2)">
          开口次数
        </span>
      </div>
      <div class="box-filter">
        <div class="item" v-for="(item,idx) in tagData" :key="idx" @click="changeTag(item)">
          <span :class="item.title === curTag.title ? 'i sel': 'i'"></span>
          {{item.title}}
        </div>
      </div>
      <div id="chartTag" style="width: 100%; height: 300px;"></div>
    </div>

    <h3 class="title">
      <strong>接待客户明细</strong>
      <span>
          <el-radio-group v-model="inNum.r3" @change="getList">
            <el-radio v-for="item in inNumList" :key="item" :label="item"></el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <ul class="table">
        <li class="header">
          <span class="c1">客户手机号</span>
          <span class="c2">接待时间</span>
          <span class="c5">意向车型</span>
          <span class="c3">接待质量得分</span>
        </li>
        <div v-if="!tableData.length" class="empty">暂无数据</div>
        <li v-else v-for="(item, idx) in tableData" :key="idx"
          @click="$router.push({name: 'receptionShow', query: {id:item.id}})"
          >
          <span class="c1">{{item.name}}</span>
          <span class="c2">{{item.datetime}}</span>
          <span class="c5">{{item.target_model}}</span>
          <span class="c3">{{item.score}}</span>
        </li>
      </ul>
    </div>

    <NavBar tab="销售分析"></NavBar>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm } from '@/api'
import NavBar from '@/components/navbar.vue';
import * as echarts from 'echarts'
import { tagOpts, radarOpts } from '@/utils/chart'



export default {
  data() {
      return {
        order_by: 1,
        selPop: {
          target_model: false,
          user_level: false,
        },
        selObj: {
          target_model: '',
          user_level: '',
        },
        totalAccount: [],
        tableData: [],
        carModelOpts: [],
        userLevelOpts: [],
        tagData: [],
        tagTab: 1,
        curTag: {},
        inNum: {
          r1: '首次进店',
          r2: '首次进店',
          r3: '首次进店',
          r4: '首次进店',
        },
        inNumList: ['首次进店', '再次进店'],
        options: ['近30天', '今日', '本周', '上周', '本月'],
        ruleForm: {
          date_type: '今日'
        },
        online: false,
        electricity: 0,
        startOpts: {
          disabledDate: (time) => {
              return new Date(time) > new Date(this.end_date || '')
          }
        },
        endOpts: {
          disabledDate: (time) => {
              return new Date(time) < new Date(this.start_date || '')
          }
        }
      }
  },
  components:{
    NavBar,
  },
  methods : {
    changeDate() {
      this.ruleForm = {
        ...this.ruleForm,
        start_date: null,
        end_date: null,
      }
      this.loadData();
    },
    changeTagTab(l) {
      this.tagTab = l;
      this.changeTag(this.curTag, l);
    },
    changeTag(item) {
      this.curTag = item || {};
      const d = this.curTag.dataList || [];
      const labelArr = d.map(o => o.label);
      const timesArr = d.map(o => o.value);
      const rateArr = d.map(o => o.rate);
      const valueArr = this.tagTab == 2 ? timesArr : rateArr;
      this.tagChart.clear();
      this.tagChart.setOption(tagOpts({
        labelArr,
        valueArr,
        tagTab: this.tagTab
      }));
    },
    onSort() {
      this.order_by = this.order_by == 1 ? 0 : 1;
      this.getList();
    },
    onSel(obj) {
      this.selPop = {};
      this.selObj = {
        ...this.selObj,
        ...obj
      }
      this.getList();
    },
    onToggle(k) {
      this.selPop = {
        [k]: !this.selPop[k],
      }
      setTimeout(() => {
        const dom = document.getElementById(this.selObj[k]);
        dom && dom.scrollIntoView({
          behavior: 'smooth',
        });
      }, 0);
    },
    getUserLevel() {
      postForm('/api/option/deal_edit', {field: 'user_level'}).then(res => {
        if (res.status === 1) {
          this.userLevelOpts = res.data || [];
        }
      })
    },
    getCarModel() {
      postForm('/api/option/target_model').then(res => {
        if (res.status === 1) {
          this.carModelOpts = res.data || [];
        }
      })
    },
    getTotal() {
      const {ruleForm} = this;
      postForm('/h5/seller/statisticalAnalysis1', {
        ...ruleForm,
        in_num: this.inNum.r1,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.totalAccount = res.data.totalAccount || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getChart() {
      const {ruleForm} = this;
      postForm('/h5/seller/statisticalAnalysis2', {
        ...ruleForm,
        in_num: this.inNum.r2,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.analyzeList = res.data.analyzeList;
        const tags = this.analyzeList;
        const labels = tags.map(o => o.title);
        const values = tags.map(o => parseFloat(o.avgValue));
        this.myChart.clear();
        this.myChart.setOption(radarOpts({labelArr: labels, valueArr: values}));

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getLabel() {
      const {ruleForm} = this;
      postForm('/h5/seller/statisticalAnalysis4', {
        ...ruleForm,
        in_num: this.inNum.r4,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
          this.tagData = res.data.tagList || [];
          this.changeTag(this.tagData[0]);

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      const {ruleForm, order_by} = this;
      const {target_model, user_level} = this.selObj;
      postForm('/h5/seller/statisticalAnalysis3', {
        ...ruleForm,
        //target_model,
        //user_level,
        //order_by,
        in_num: this.inNum.r3,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.tableData = res.data.checkList || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    loadData() {
      const {start_date, end_date} = this.ruleForm;
      if (start_date || end_date) {
        this.ruleForm = {
          ...this.ruleForm,
          date_type: '自定义',
        }
      }
      this.getTotal();
      this.getChart();
      this.getLabel();
      this.getList();
    },
  },
  created() {
    const q = Vue.ls.get('PAGE_COUNT') || {};
    Object.keys(q).forEach(o => {
      this[o] = q[o]
    });
    this.loadData();
    //this.getCarModel();
    //this.getUserLevel();
  },
  mounted () {
    const domA = document.getElementById('chart');
    this.myChart = echarts.init(domA);

    const dTag = document.getElementById('chartTag');
    this.tagChart = echarts.init(dTag);
  },
  beforeDestroy() {
    const page = this.$router.currentRoute;
    const q = page && page.name == 'receptionShow' ? {
      ruleForm: this.ruleForm,
      selObj: this.selObj,
      order_by: this.order_by,
      inNum: this.inNum ,
    } : {};
    Vue.ls.set('PAGE_COUNT', {...q});
  }
}

</script>

<style lang="less">

.page-count {

  .el-radio__inner {
    background: url('~@/assets/radio.png') center center no-repeat;
    background-size: cover;
    border: 0;
    &::after {
      display: none;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: url('~@/assets/radio_sel.png') center center no-repeat;
    background-size: cover;

  }


  .box-filter {
    padding: 1rem;
    .item {
      display: inline-block;
      margin-right: 0.5rem;
      padding: 0.25rem 0;
      cursor: pointer;
      .i {
        width: 1rem;
        height: 0.25rem;
        background: #e8e8e8;
        display: inline-block;
        margin-right: 0.25rem;
        vertical-align: middle;

        &.sel {
          background: #6F95FC;
        }
      }
    }
  }
  
  .box-tag {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.7rem;
      line-height: 2rem;
      width: 47%;
      text-align: center;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      background: #F5F5F5;

      &.sel {
        border: 1px solid #1890ff;
        color: #377EEA;
        background: #fff;
      }
    }
  }

  .table {
    color: #000;
    font-size: 0.6rem;
    .empty {
      min-height: 5rem;
      line-height: 5rem;
      text-align: center;
      color: #ccc;
    }
    li {
      display: flex;
      background: #fff;
      align-items: center;

      >span {
        flex: 1;
        line-height: 1.3;
        padding: 0.5rem 0.2rem;
        word-break:break-all;
        position: relative;

        &.c2 {
          min-width: 3.8rem;
          word-break: break-word;
        }
        &.sort {
          display: flex;
          white-space: nowrap;
          align-items: center;
        }
      }
      .box-sort {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        i {
          color: #bfbfbf;
          margin: -2px 0;
        }
      }
      
      .box-sel {
        white-space: nowrap;
        transition: all .3s;
      }
      .box-opts {
        position: absolute;
        left: 0.2rem;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 10px 0 #ccc;
        box-sizing: border-box;
        max-height: 12rem;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1;
        margin-top: 0.2rem;
        a {
          padding: 0 0.2rem;
          display: block;
          white-space: nowrap;
          line-height: 1.8;
          min-width: 3rem;

          &:hover {
            background: #f5f7fa;
          }

          &.sel {
              color: #409eff;
              font-weight: 700;
          }
        }
      }

      &.header {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        font-weight: 500;
        background: #EBF2FD;
        padding: 0.2rem 0;
      }
      &:nth-child(even) {
        background: #F5F5F5;
      }
    }
  }
  h3.title {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;

    >span {
      flex: 1;
      text-align: right;
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
    }
  }

  .box-result {
    padding: 0;
    display: flex;
    flex- wrap: wrap;
    .score {
      min-width: 50%;
      text-align: center;
      border-top: 1px solid #EFEFEF;
      border-left: 1px solid #EFEFEF;
      padding: 1rem 0;

      &:nth-child(1), &:nth-child(2) {
        border-top: 0;
      }
      &:nth-child(odd) {
        border-left: 0;
      }

      .num {
        font-size: 0.9rem;
      }
      .name {
        font-size: 0.6rem;
        color: #788798;
      }
    }
  }

.box-top {
  height: 3.5rem;
  .fixed {
    max-width: 750px;
    width: 100%;
    z-index: 1000;
    background: url('~@/assets/bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0.5rem 0.8rem;
    position: fixed;
    top: 0;
  }

  .box-state {
    height: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    .wifi {
      background: rgba(0,0,0,0.15);
      border-radius: 8px;
      padding: 0.1rem 0.5rem;
      color: #F5F5F5;
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      i {
        margin-right: 0.2rem;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
      }
    }
    .energy {
      display: flex;
      align-items: center;
      font-size: 0.6rem;
      span {
        width: 0.4rem;
        height: 0.7rem;
        display: inline-block;
        background-size: auto 100%;
        vertical-align: middle;
        position: relative;
        border: 1px solid #333;
        border-radius: 1px;
        margin-right: 0.2rem;
        &:before {
          content: ' ';
          position: absolute;
          top: -0.1rem;
          left: 50%;
          transform: translateX(-50%);
          width: 0.2rem;
          height: 0.1rem;
          background: #333;
        }

        i {
          position: absolute;
          bottom: 1px;
          left: 1px;
          right: 1px;
          display: block;
          background: #333;
          max-height: 0.5rem;
        }
      }
    }
  }
}
.box-tab {
  display: flex;
  justify-content: space-between;
  >p {
    display: flex;
    align-items: center;
    max-width: 48%;
    flex: 1;
    background: #fff;
    border-radius: 0.6rem;
    height: 2.5rem;
    justify-content: center;
    font-size: 0.8rem;
    margin: 0;

    .el-input__inner {
      border: 0;
      padding: 0;
      text-align: center;
      font-size: 0.65rem;
      font-weight: 500;
    }
    .el-select {
      .el-input__inner, .el-select__caret {
        color: #377EEA;
      }
      .el-icon-arrow-up:before {
          content: "";
      }
    }
    .icon {
      height: 0.8rem;
      margin-left: 0.5rem;
    }
  }
}

.el-select .el-input {
  width: 5rem;
}
.input-with-select .el-input-group__prepend, .el-input-group__append {
  background-color: #fff;
}

}
</style>
