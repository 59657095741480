import Vue from 'vue'
import Vuex from 'vuex'
import { postData, getData } from '../api/index'
import { getToken, setToken, removeToken } from '../utils/auth'
import {  setCookie, removeCookie } from '../utils/cookie'
import { checkRole,getFormatDate } from '../utils/index'
Vue.use(Vuex)
const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: {},
    query:{...getFormatDate('month')}
  }
}
export default new Vuex.Store({
  state:  getDefaultState(),
  mutations: {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_TOKEN:(state,token)=>{
      state.token=token
    },
    SET_USER:(state,userInfo)=>{
      state.userInfo=userInfo
    },
    SET_QUERY:(state,query)=>{
      state.query=query
    },
    SET_DB_QUERY:(state,query)=>{
      state.queryDB=query
    }
  },
  actions: {
     authorization({commit},adminId){
       return new Promise(async(resolve,reject)=>{
         try{
          const {data:{token,admin_type_id,name,crop_id_default,company_ids=null, brand_ids, ...rest}}=await postData("/api/admin/loginmf",{adminId});
          commit("SET_TOKEN",token);
          setCookie("AUTH_TOKEN",token)
          setCookie("isSuper",admin_type_id)
          setCookie("adminId",admin_type_id)
          setCookie("cropId",crop_id_default)
          setCookie("name",name)
          setCookie("company_ids",company_ids)
          setCookie("brand_ids",brand_ids)
          setCookie("brandId", rest.brand_id)
          setToken(token);
          const {data:authList}=await getData("/api/admin/auth");
          const  result=checkRole(authList);
          resolve(result);
         }catch(e){
           const dom = document.getElementById('app');
           dom.innerText = e;
           dom.style.color= '#fff';
           dom.style.textAlign= 'center';
           dom.style.marginTop= '300px';
           reject(e)
         }
       })
    },
    login({commit},userInfo){
       return new Promise(async(resolve,reject)=>{
         try{
          const {username,password}=userInfo;
          const {data:{token,admin_type_id,name,crop_id_default,company_ids=null, brand_ids, ...rest}}=await postData("/api/admin/loginm",{username,password});
  
          console.log(rest);
          commit("SET_TOKEN",token);
          setCookie("isSuper",admin_type_id)
          setCookie("adminId",admin_type_id)
          setCookie("cropId",crop_id_default)
          setCookie("name",name)
          setCookie("company_ids",company_ids)
          setCookie("brand_ids",brand_ids)
          setCookie("brandId", rest.brand_id)
          setToken(token);
          const {data:authList}=await getData("/api/admin/auth");
          const  result=checkRole(authList);
          resolve(result);
         }catch(e){
           reject(e)
         }
      
       })
       
       
    },
    resetToken({ commit }) {
      return new Promise(resolve => {
        removeToken()  
        removeCookie("isSuper")
        removeCookie("name")
        removeCookie("company_ids")
        removeCookie("AUTH_TOKEN")
        commit('RESET_STATE')
        resolve()
      })
    }
  }

})
