export function setDefaultValue(list) {
  return list.reduce((s,o) => {
    if ((o.arr || []).length == 1) {
      return {
        ...s,
        [o.key]: o.arr[0].value
      }
    } else {
      return s;
    }
  }, {});
}

export default {
  setDefaultValue,
}

