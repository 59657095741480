import {getData} from './index'
import {getRole} from '@/utils/getRole'

export async function getOptions(params){
  const isSuper = getRole().isSuper;
  if (params.table == 'brand' && !isSuper) {
    delete params.crop_id
  }

  return await getData("/api/option/index", {
      ...params,
      auth: 1,
    });
}
