<template>
  <div class="page-file">
    
    <div class="sec">
      <div class="custom-info">
        <div class="item" v-for="(item, index) in customInfo" :key="index">
          <span class="label">{{item.label}}</span> 
          <strong>{{item.text}}</strong>
        </div>
      </div>
    </div>

    <h3 class="title">客户分析</h3>
    <div class="sec" style="min-height: 50px;">
      <fragment v-for="(item,idx) in customAnalysis" :key="idx">
      <div class="box-analysis" v-if="item.list.length">
        <div class="tit">{{item.name}}</div>
        <div class="list">
          <span v-for="(l,i) in item.list" :class="l.checked ? 'sel' : ''" :key="i">{{l}}</span>
        </div>
      </div>
      </fragment>
    </div>

    <fragment v-if="!isVolvo">
    <h3 class="title">客户关注问题</h3>
    <div class="sec">
      <div class="list-type">
        <a :class="curQaType.label === item.label ? 'sel' : ''"
         v-for="(item,idx) in questionTypeList"
         :key="idx"
          @click="onSelType(item)">
          <span class="num"><strong>{{item.value}}</strong>{{item.unit}}</span>
          <span v-if="item.value !== ''">{{item.label}}</span>
        </a>
      </div>
    </div>
    <div class="sec" style="padding: 0 0 0.5rem 0;">
      <el-table class="table" stripe
        :data="questionHotList"
        style="width: 100%">
        <el-table-column
          width="50"
          align="center"
          type="index"
          label="排名"
          >
        </el-table-column>
        <el-table-column
          prop="label"
          align="center"
          label="热门问题"
          >
        </el-table-column>
        <el-table-column
          width="50"
          align="center"
          prop="value"
          label="次数">
        </el-table-column>
      </el-table>
    </div>
    </fragment>
  </div>
</template>

<script>
import { postForm } from '@/api'
import {getRole} from '@/utils/getRole'

export default {
  data() {
      return {
        curQaType: {},
        customInfo: [],
        questionTypeList: [],
        questionHotList: [],
        customAnalysis: [],
        curStep: 't2',
        isVolvo: false,
      }
  },
  methods: {
    onSelType(item) {
      console.log(item);
      this.curQaType = item || {};
      this.onSearch();
    },
    
    onSearch() {
      const typeId = this.curQaType.id;
      postForm('/h5/seller/follow', {
        id: this.id,
        type_id: typeId,
      }).then(res => {
          if (res.status === 1) {
            const {userInfo, questionHotList, questionTypeList, customAnalysis} = res.data;
            this.customInfo = userInfo;
            this.questionTypeList = questionTypeList || [];
            if (!typeId && this.questionTypeList.length > 0) {
              this.onSelType(this.questionTypeList[0]);
            }
            this.questionHotList = questionHotList;
            this.customAnalysis = [
              {
                name: '竞品车型',
                list: customAnalysis.competing_model
              },
              {
                name: '关注点',
                list: []
              },
              {
                name: '购车次数',
                list: []
              },
              {
                name: '付款方式',
                list: []
              },
            ];
          } else {
            this.$message.error(res.msg);
          }
        });
    }
  },
  components: {
  },
  created() {
    this.isVolvo = getRole().isVolvo;

    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.onSearch(true);
  },
}
</script>

<style lang="less">

.page-file {
  position: relative;
  padding-bottom: 1rem;

  h3.title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .box-analysis {
    padding: 0.8rem 0;
    .tit {
      font-size: 0.7rem;
      font-weight: 500;
    }
    .list {
      span {
        display: inline-block;
        background: #F5F5F5;
        margin-right: 0.5rem;
        margin-top: 0.5rem; 
        border-radius: 4px;
        padding: 1px 5px;
        border: 1px solid #F5F5F5;

        &.sel {
          background: #fff;
          border: 1px solid #377EEA;
          color: #377EEA;
        }
      }
    }
  }

  .table {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 0;
    font-size: 0.6rem;
    thead {
      color: #000;
      th.el-table__cell {
        background: rgba(55,126,234,0.1);
      }
    }
    td.el-table__cell {
      padding: 0.4rem 0;
    }
    .cell {
      padding: 0;
    }
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border: 0;
  }
  
  .list-type {
    display: flex;
    flex-wrap: wrap;
    padding: 0.6rem 0;
    a {
      padding: 0.35rem 0;
      min-width: 30%;
      max-width: 33.3%;
      flex: 1;
      text-align: center;
      display: block;

      span {
        display: block;
        font-size: 0.6rem;
        color: #788798;

        &.num {
          color: #000;
        }

        strong {
          font-size: 0.9rem;
          margin-right: 0.1rem;
        }
      }
    }
    .sel {
      border-radius: 0.4rem;
      background: #EBF2FD;
      .num, strong, span {
        color: #377EEA;
      }
    }
  }
  
  .custom-info {
    .item {
      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EFEFEF;
      padding: 0.5rem 0;

      span {
        opacity: 0.64;
      }
      strong {
        font-weight: 400;
      }
      &:last-child {
        border: 0;
      }
    }
  }

}

</style>
