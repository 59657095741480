import * as echarts from 'echarts'

const borderColor = '#D2DCF3';
const fontColor = '#626D83';


const title = {
  text: '暂无数据',
  left: 'center',
  top: 'center',
  textStyle: {
    color: '#aaa',
    fontSize: 20
  },
}


const radar =  {
    shape: 'circle',
    splitNumber: 5,
    nameGap: 16,
    radius: 90,
    center:['50%','50%'],
    name: {
        textStyle: {
          //backgroundColor: '#73baf8',
          color: '#4379F9',
          fontSize: 12,
          borderRadius: 2,
          padding: 2
        }
    },
    splitArea: {
        show: false
    },
    splitLine: {
        lineStyle: {
            color: borderColor
        }
    },
    axisLine: {
        lineStyle: {
            color: borderColor
        }
    }
};

const radarSeries = {
  type: 'radar',
  itemStyle: {
    color: '#1890ff'
  },
  areaStyle: {
    opacity: 0.3
  },
  label: {
    show: true,
    position: 'inside',
    color: '#000'
  }
}

export function radarMultiOpts({labelArr=[], valueArr=[], nameArr=[]}) {
  const indicator = labelArr.map(o => ({name: o, max: 100}));
  let data = [];
  const color = ['#338dfa', '#fcc147'];
  if(valueArr.length) {
    data = valueArr.map((o,i) => ({
      symbolSize: 6,
      itemStyle: {
        color: color[i],
      },
      label: {
        position: 'top',
        color: color[i],
        fontSize: 12,
        distance: 1,
      },
      value: o,
      name: nameArr[i],
    }));
  }
  const option = {
      legend: {
          data: nameArr,
          top: '0px'
      },
      title: {
        ...title,
        show: !valueArr.length,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.value.reduce((s, o, i) => (s = s + labelArr[i] + '：' + o + '<br/>'), '')
        }
      },
      grid: {
        containLabel: true
      },
      calculable : true,
      radar: {
        ...radar,
        indicator,
      },
      series: [
          {
              ...radarSeries,
              data,
          },
      ]
  };

  return option;
}

export function radarOpts({labelArr=[], valueArr=[]}) {
  const indicator = labelArr.map(o => ({name: o, max: 100}));
  let data = [];
  if(valueArr.length) {
    data = [{
      symbolSize: 6,
      itemStyle: {
        color: '#fcc147',
      },
      label: {
        position: 'top',
        color: '#b10212',
        fontSize: 12,
        distance: 1,
      },
      value: valueArr,
      name: '-',
    }]
  }
  const option = {
      title: {
        ...title,
        show: !valueArr.length,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.value.reduce((s, o, i) => (s = s + labelArr[i] + '：' + o + '<br/>'), '')
        }
      },
      grid: {
        containLabel: true
      },
      calculable : true,
      radar: {
        ...radar,
        indicator,
      },
      series: [
          {
              ...radarSeries,
              data,
          },
      ]
  };

  return option;
}


export function stepOpts({labelArr=[], valueArr=[]}) {
  const option = {
    title: {
      ...title,
      show: !valueArr.length,
    },
    grid: {
        top: '0px',
        left: '0px',
        bottom: '20px',
        right: '70px',
        containLabel: true
    },
    calculable : true,
    yAxis: {
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
          interval:0,
          formatter: (val) => {
            if (val.length < 5) {
              return val;
            }
            const charArr = val.split('');
            let str = '' 
            for(let i = 0; i < charArr.length; i++) {
              const idx = Math.ceil(charArr.length / 2);
              str += charArr[i];
              if(i == idx-1) str += '\n';
            } 
            return str;
          }
        },
        type: 'category',
        name: '标签',
        data: labelArr
    },
    xAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
        },
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        type: 'value',
        name: '平均分',
        max: 100,
        boundaryGap: [0, 1]
    },
    dataZoom: [
      {
        show: labelArr.length > 9,
        yAxisIndex: 0,
        type: 'slider',
        startValue: labelArr.length,
        minValueSpan: 8,
        maxValueSpan: 8,
        width: '10px',
        right: 0,
        moveHandleSize: 0,
      },
    ],
    series: [
        {
            type: 'bar',
            data: valueArr,
            barWidth: 10,
            barGap: '20%',
            itemStyle:{
                normal:{
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{ 
                        offset: 0,
                        color: '#377EEA'
                    }, {
                        offset: 1,
                        color: '#377EEA'
                    }])

                }
            },
            label: {
              show: true,
              position: 'right',
              color: '#000',
              formatter: function(params) {
                return params.value;
              }
            }
        }
    ]
  };
  
  return option;
}

export function tagOpts({labelArr=[], valueArr=[], tagTab=1}) {
  const tagName = {1: '开口率', 2: '开口次数'};
  const sign = {1: '%', 2: '次'};
  const option = {
    title: {
      ...title,
      show: !valueArr.length,
    },
    grid: {
        top: '0px',
        left: '0px',
        bottom: '20px',
        right: '70px',
        containLabel: true
    },
    calculable : true,
    yAxis: {
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
          interval:0,
          formatter: (val) => {
            if (val.length < 5) {
              return val;
            }
            const charArr = val.split('');
            let str = '' 
            for(let i = 0; i < charArr.length; i++) {
              const idx = Math.ceil(charArr.length / 2);
              str += charArr[i];
              if(i == idx-1) str += '\n';
            } 
            return str;
          }
        },
        type: 'category',
        name: '标签',
        data: labelArr
    },
    xAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
        },
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        type: 'value',
        name: tagName[tagTab],
        max: 100,
        boundaryGap: [0, 1]
    },
    dataZoom: [
      {
        show: labelArr.length > 9,
        yAxisIndex: 0,
        type: 'slider',
        maxValueSpan: 8,
        width: '10px',
        right: 0,
      },
    ],
    series: [
        {
            type: 'bar',
            data: valueArr,
            barWidth: 10,
            barGap: '20%',
            itemStyle:{
                normal:{
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{ 
                        offset: 0,
                        color: '#FAC15C'
                    }, {
                        offset: 1,
                        color: '#FAC15C'
                    }])

                }
            },
            label: {
              show: true,
              position: 'right',
              color: '#000',
              formatter: function(params) {
                return params.value + sign[tagTab];
              }
            }
        }
    ]
  };
  
  return option;
}


export function lengthTrendOpts(list) {
const labelArr = list[0].labelArr;
const nameArr = list.map(o => o.title);

const yAxisOpts = {
  type: 'value',
  minInterval: 1,
  nameTextStyle: {
    color: fontColor,
  },
  axisLabel: {
    color: fontColor,
    show: true,
  },
  axisTick: {
    show: false
  },
  axisLine: {
    show: false,
    lineStyle: {
        color: borderColor
    }
  },
};
const option = {
    animation: false,
    legend: {
        data: nameArr,
        top: '0px'
    },
    tooltip: {
        trigger: 'axis',
    },
    xAxis: {
        data: labelArr,
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
          interval: 0,
          rotate: labelArr.length > 3 ? 40 : 0,
          textStyle: {
            color: fontColor,
            fontSize: '8px',
          },
        },
        axisLine: {
          //show: false,
          lineStyle: {
              color: borderColor
          }
        },
        axisPointer: {
            lineStyle: {
                color: fontColor,
                opacity: 0.5,
                width: 1
            },
        },
        splitLine: {
            show: false
        }
    },
    yAxis: [
      {
        ...yAxisOpts,
        name: `${list[0].unit}`,
        splitLine: {
            show: false
        },
      },
      {
        ...yAxisOpts,
        name: `${list[1].unit}`,
        splitLine: {
            show: false
        },
      },
    ],
    grid: {
        top: 50,
        left: 40,
        right: 30,
        bottom: 60,
    },
    series: [
        {
            name: `${list[0].title}`,
            type: 'line',
            symbol: 'none',
            yAxisIndex: 0,
            itemStyle: {
                color: '#377EEA'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#377EEA'
                }, {
                    offset: 1,
                    color: '#fff'
                }])
            },
            data: list[0].valueArr
        },
        {
            name: `${list[1].title}`,
            type: 'line',
            symbol: 'none',
            yAxisIndex: 1,
            itemStyle: {
                color: '#FAC15C'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FAC15C'
                }, {
                    offset: 1,
                    color: '#fff'
                }])
            },
            data: list[1].valueArr
        }

    ]
};
  return option;
}

export function afStepOpts({labelArr=[], valueArr=[]}) {
  const option = {
    title: {
      ...title,
      show: !valueArr.length,
    },
    grid: {
        top: '0px',
        left: '0px',
        bottom: '20px',
        right: '85px',
        containLabel: true
    },
    calculable : true,
    yAxis: {
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
          interval:0,
          formatter: (val) => {
            if (val.length < 5) {
              return val;
            }
            const charArr = val.split('');
            let str = '' 
            for(let i = 0; i < charArr.length; i++) {
              const idx = Math.ceil(charArr.length / 2);
              str += charArr[i];
              if(i == idx-1) str += '\n';
            } 
            return str;
          }
        },
        type: 'category',
        name: '标签',
        data: labelArr
    },
    xAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
        },
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        type: 'value',
        name: '开口率%',
        max: 100,
        boundaryGap: [0, 1]
    },
    dataZoom: [
      {
        show: labelArr.length > 9,
        yAxisIndex: 0,
        type: 'slider',
        startValue: labelArr.length,
        minValueSpan: 8,
        maxValueSpan: 8,
        width: '10px',
        right: 0,
        moveHandleSize: 0,
      },
    ],
    series: [
        {
            type: 'bar',
            data: valueArr,
            barWidth: 10,
            barGap: '20%',
            itemStyle:{
                normal:{
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{ 
                        offset: 0,
                        color: '#0070C0'
                    }, {
                        offset: 1,
                        color: '#0070C0'
                    }])

                }
            },
            label: {
              show: true,
              position: 'right',
              color: '#000',
              formatter: function(params) {
                return params.value + '%';
              }
            }
        }
    ]
  };
  
  return option;
}

export function afLengthTrendOpts(list) {
const labelArr = list[0].labelArr;
const nameArr = list.map(o => o.title);

const yAxisOpts = {
  type: 'value',
  minInterval: 1,
  nameTextStyle: {
    color: fontColor,
  },
  axisLabel: {
    color: fontColor,
    show: true,
  },
  axisTick: {
    show: false
  },
  axisLine: {
    show: false,
    lineStyle: {
        color: borderColor
    }
  },
};
const option = {
    animation: false,
    legend: {
        icon: 'rect',
        itemWidth: 8,
        itemHeight: 5,
        data: nameArr,
        top: '0px'
    },
    tooltip: {
        trigger: 'axis',
    },
    xAxis: {
        data: labelArr,
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
          interval: 0,
          rotate: labelArr.length > 3 ? 40 : 0,
          textStyle: {
            color: fontColor,
            fontSize: '8px',
          },
        },
        axisLine: {
          //show: false,
          lineStyle: {
              color: borderColor
          }
        },
        axisPointer: {
            lineStyle: {
                color: fontColor,
                opacity: 0.5,
                width: 1
            },
        },
        splitLine: {
            show: false
        }
    },
    yAxis: [
      {
        ...yAxisOpts,
        name: `${list[0].unit}`,
        splitLine: {
            show: false
        },
      },
      {
        ...yAxisOpts,
        name: `${list[1].unit}`,
        splitLine: {
            show: false
        },
      },
    ],
    grid: {
        top: 50,
        left: 30,
        right: 30,
        bottom: 60,
    },
    series: [
        {
            name: `${list[0].title}`,
            type: 'line',
            symbol: 'none',
            yAxisIndex: 0,
            itemStyle: {
                color: '#377EEA'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#377EEA'
                }, {
                    offset: 1,
                    color: '#fff'
                }])
            },
            data: list[0].valueArr
        },
        {
            name: `${list[1].title}`,
            type: 'line',
            symbol: 'none',
            yAxisIndex: 1,
            itemStyle: {
                color: '#FAC15C'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FAC15C'
                }, {
                    offset: 1,
                    color: '#fff'
                }])
            },
            data: list[1].valueArr
        }

    ]
};
  return option;
}

