<template>
  <fragment>
      <a-form-model-item label="集团名称" v-if="showCrop">
        <a-select v-model="query.crop_id"
          :placeholder="placeholder.crop"
          :allowClear="(optList.cropOtps || []).length>1"
          @change="handleCropChange"
          :options="optList.cropOtps"/>
      </a-form-model-item>
      <a-form-model-item label="经销商集团" v-if="showDealerGroup && role.isMaster">
        <a-select v-model="query.dealer_group_id"
          :placeholder="placeholder.dealerGroup"
          showSearch
          :filterOption="filterOption"
          :allowClear="(optList.dealerGroupOtps || []).length>1"
          @change="handleDealerGroupChange"
          :options="optList.dealerGroupOtps"/>
      </a-form-model-item>
      <a-form-model-item label="品牌名称" v-if="showBrand">
        <a-select v-model="query.brand_id"
          :placeholder="placeholder.brand"
          :allowClear="(optList.brandOtps || []).length>1"
          @change="handleBrandChange"
          :options="optList.brandOtps"/>
      </a-form-model-item>
      <fragment class="box-org" v-if="isShow">
      <a-form-model-item label="大区名称" v-if="!hideRegion">
        <a-select v-model="query.region_id"
          :placeholder="placeholder.region"
          :allowClear="(optList.regionOtps || []).length>1"
          @change="handleRegionChange"
          :options="optList.regionOtps"/>
      </a-form-model-item>
      <a-form-model-item label="小区名称" v-if="!hideArea">
        <a-select v-model="query.area_id"
          :placeholder="placeholder.area"
          :allowClear="(optList.areaOtps || []).length>1"
          @change="handleAreaChange"
          :options="optList.areaOtps"/>
      </a-form-model-item>
      <a-form-model-item label="门店类型" v-if="showComType && role.isMaster">
        <a-select v-model="query.company_type_id"
          :placeholder="placeholder.companyType"
          :allowClear="(optList.companyTypeOtps || []).length>1"
          @change="handleCompanyTypeChange"
          :options="optList.companyTypeOtps"/>
      </a-form-model-item>
      <a-form-model-item label="公司名称" v-if="!hideCompany">
        <a-select v-model="query.company_id"
          :placeholder="placeholder.company"
          :allowClear="(companyOtps || []).length>1"
          showSearch
          @change="handleCompanyChange"
          :filterOption="filterOption"
          :options="companyOtps"/>
      </a-form-model-item>
      <a-form-model-item label="部门名称" v-if="!hideDepartment">
        <a-select v-model="query.department_id"
          :placeholder="placeholder.department"
          :allowClear="(optList.departmentOtps || []).length>1"
          @change="handleDepartmentChange"
          :options="optList.departmentOtps"/>
      </a-form-model-item>
    </fragment>
  </fragment>
</template>

<script>
import Vue from 'vue'
import actions from '@/api/action'
import {getRole} from '@/utils/getRole'
import {setDefaultValue} from '@/utils/common'

export default {
  props: {
    showDealerGroup: false,
    hideCrop: false,
    hideBrand: false,
    hideRegion: false,
    hideArea: false,
    hideCompany: false,
    hideDepartment: false,
    showDealerCode: false,
    showComType: false,
    vendorOne: false,
    defaultData: {},
    defaultList: {},
    placeholder: {
      type: Object,
      default: () => {
        return {
          crop: '',
          dealerGroup: '',
          brand: '',
          region: '',
          area: '',
          company: '',
          companyType: '',
          department: '',
        };
      }
    },
  },
  data () {
    return {
      isShow: false,
      role: null,
      name: '',
      query: {},
      optList: {},
    }
  },
  watch: {
    defaultData(v) {
      this.query = {...this.defaultData};
    },
    defaultList() {
      const {areaOtps, companyOtps} = this.defaultList || {};
      this.optList = {...this.optList, areaOtps, companyOtps};
    }
  },
  computed:{
    showCrop() {
      if (this.hideCrop || this.role.isDealers) {
        return false;
      }
      if (this.role.isVendor) {
        const {cropOtps} = this.optList || {};
        return this.vendorOne ? (cropOtps||[]).length > 1 : false;
      }
      return true;
    },
    showBrand() {
      if (this.hideBrand || this.role.isDealers) {
        return false;
      }
      if (this.role.isVendor) {
        const {brandOtps} = this.optList || {};
        return (brandOtps||[]).length > 1 ? true : false;
      }
      return true;
    },
    companyOtps() {
      const {companyOtps} = this.optList;
      if (this.showDealerCode) {
        return codeName(companyOtps);
      }
      return companyOtps;
    }
  },
  created() {
    this.role = getRole();
    this.isShow = this.role.isMaster;
  },
  async mounted () {
    if (this.isShow) {
      this.loadData();
    }
  },
  methods: {
    async loadData(resetData) {
      const {...rest} = resetData || this.defaultData;
      const optList = {};
      if (rest.crop_id) {
        const brandOtps = await actions.getOptionList('brand', { crop_id: rest.crop_id });
        optList.brandOtps = brandOtps;
        const dealerGroupOtps = await actions.getOptionList('dealer_group', {
          crop_id: rest.crop_id,
        });
        optList.dealerGroupOtps = dealerGroupOtps;
        const companyTypeOtps = await actions.getCompanyTypeList({
          crop_id: rest.crop_id,
          dealer_group_id: rest.dealer_group_id,
          brand_id: rest.brand_id,
          region_id: rest.region_id,
          area_id: rest.area_id,
          company_type_id: rest.company_type_id
        });
        optList.companyTypeOtps = companyTypeOtps;
      } else {
        optList.brandOtps = await actions.getBrandOtps();
        optList.dealerGroupOtps = await actions.getOptionList('dealer_group');
        optList.companyTypeOtps = this.showComType ? await actions.getCompanyTypeList() : [];
      }
      if (rest.crop_id || rest.brand_id) {
        const regionOtps = await actions.getOptionList('region', {
          crop_id: rest.crop_id,
          brand_id: rest.brand_id
        });
        optList.regionOtps = regionOtps;
      } else {
        optList.regionOtps = !this.hideRegion ?  await actions.getRegionOtps() : [];
      }
      if (rest.region_id) {
        const areaOtps = await actions.getOptionList('area', {
          crop_id: rest.crop_id,
          brand_id: rest.brand_id,
          region_id: rest.region_id
        });
        optList.areaOtps = areaOtps;
      } else {
        optList.areaOtps = !this.hideArea ? await actions.getAreaOtps() : [];
      }
      if (rest.area_id) {
        const companyOtps = await actions.getOptionList('company', {
          crop_id: rest.crop_id,
          brand_id: rest.brand_id,
          region_id: rest.region_id,
          area_id: rest.area_id,
          company_type_id: rest.company_type_id
        });
        optList.companyOtps = companyOtps;
      } else {
        optList.companyOtps = !this.hideCompany ? await actions.getCompanyOtps() : [];
      }

      this.initData = {
        cropOtps: this.hideCrop ? [] : await actions.getCropOtps(),
        departmentOtps: this.hideDepartment ? [] : await actions.getDepartmentOtps(),
      }
      this.optList = {...this.initData, ...optList}
      this.query = {...formatQ(this.defaultData)};
      this.setDefaultValue();
    },
    setDefaultValue() {
      const {departmentOtps, cropOtps, brandOtps,
        regionOtps, areaOtps, companyOtps} = this.optList;

      const q = setDefaultValue([
        {key: 'crop_id', arr: cropOtps},
        {key: 'brand_id', arr: brandOtps},
        {key: 'region_id', arr: regionOtps},
        {key: 'area_id', arr: areaOtps},
        {key: 'company_id', arr: companyOtps},
        {key: 'department_id', arr: departmentOtps},
      ]);
      this.query = {...this.query};
    },
    async reset() {
      this.loadData({});
      this.query = {};
      this.setDefaultValue();
      this.$emit('change', this.query, this.optList);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    changeData({resetData={}, optList={}}) {
      this.query = {
        ...this.query,
        ...resetData
      }
      this.optList = {
        ...this.optList,
        ...optList
      }
      this.setDefaultValue();
      this.$emit('change', this.query, this.optList);
    },
    async handleCropChange(f) {
      const {crop_id} = this.query;
      const dealerGroupOtps = !this.showDealerGroup ? [] : await actions.getOptionList('dealer_group', {crop_id});
      const brandOtps = this.hideBrand ? [] : await actions.getOptionList('brand', {crop_id});
      const regionOtps = this.hideRegion ? [] : await actions.getOptionList('region', {crop_id});
      const areaOtps = this.hideArea ? [] : await actions.getOptionList('area', {crop_id});
      const companyTypeOtps = !this.showComType ? [] : await actions.getCompanyTypeList({ crop_id});
      const companyOtps = this.hideCompany ? [] : await actions.getOptionList('company', {crop_id});
      this.changeData({
        optList: {dealerGroupOtps, brandOtps, regionOtps, areaOtps, companyTypeOtps, companyOtps},
        resetData: {
        dealer_group_id: undefined,
        brand_id: undefined,
        region_id: undefined,
        area_id: undefined,
        company_type_id: undefined,
        company_id: undefined,
        department_id: undefined,
        admin_id: undefined,
        group_id: undefined
        },
      });
    },
    async handleDealerGroupChange() {
      const {crop_id, dealer_group_id} = this.query;
      const brandOtps = await actions.getOptionList('brand', { crop_id, dealer_group_id });
      const companyOtps = await actions.getOptionList('company', {crop_id, dealer_group_id});
      const companyTypeOtps = await actions.getCompanyTypeList({crop_id, dealer_group_id});
      this.changeData({
        optList: {brandOtps, companyOtps, companyTypeOtps},
        resetData: {
        brand_id: undefined,
        region_id: undefined,
        area_id: undefined,
        company_type_id: undefined,
        company_id: undefined,
        department_id: undefined,
        admin_id: undefined,
        group_id: undefined
        }
      });
    },
    async handleBrandChange(f) {
      const {crop_id, brand_id, dealer_group_id} = this.query;
      const companyOtps = await actions.getOptionList('company', {crop_id, dealer_group_id, brand_id});
      const companyTypeOtps = await actions.getCompanyTypeList({crop_id, dealer_group_id, brand_id});
      this.changeData({
        optList: {companyOtps, companyTypeOtps},
        resetData: {
        region_id: undefined,
        area_id: undefined,
        company_type_id: undefined,
        company_id: undefined,
        department_id: undefined,
        admin_id: undefined,
        group_id: undefined
        }
      });
    },
    async handleRegionChange(f) {
      const {crop_id, brand_id, region_id, dealer_group_id} = this.query;
      const areaOtps = await actions.getOptionList('area', {crop_id, brand_id, region_id});
      const companyOtps = await actions.getOptionList('company', {crop_id, brand_id, region_id});
      const companyTypeOtps = await actions.getCompanyTypeList({crop_id, dealer_group_id, brand_id, region_id});
      this.changeData({
        optList: {areaOtps, companyOtps, companyTypeOtps},
        resetData: {
        area_id: undefined,
        company_type_id: undefined,
        company_id: undefined,
        department_id: undefined,
        admin_id: undefined,
        group_id: undefined
        }
      });
    },
    async handleAreaChange(f) {
      const {crop_id, brand_id, region_id, area_id, dealer_group_id} = this.query;
      const companyOtps = await actions.getOptionList('company', {crop_id, brand_id, region_id, area_id});
      const companyTypeOtps = await actions.getCompanyTypeList({crop_id, dealer_group_id, brand_id, region_id, area_id});

      this.changeData({
        optList: {companyOtps, companyTypeOtps},
        resetData: {
        company_type_id: undefined,
        company_id: undefined,
        department_id: undefined,
        admin_id: undefined,
        group_id: undefined
        }
      });
    },
    async handleCompanyTypeChange(v) {
      if (this.hideCompany) {
        this.$emit('change', this.query, this.optList);
        return;
      }
      const {crop_id, brand_id, region_id, area_id, dealer_group_id, company_type_id} = this.query;
      const companyOtps = await actions.getOptionList('company', {crop_id, dealer_group_id, brand_id, region_id, area_id, company_type_id: v || company_type_id});
      this.changeData({
        optList: {companyOtps},
        resetData: {
          company_id: undefined,
          admin_id: undefined,
        }
      });
    },
    async handleCompanyChange() {
      this.changeData({
        resetData: {
          admin_id: undefined,
        }
      });
    },
    async handleDepartmentChange(f) {
      this.$emit('change', this.query, this.optList);
    },
  },
}

function codeName(list) {
  return (list || []).map(o => ({
    value: o.value,
    label: String(o.code).toUpperCase() + o.label,
  }));
}

function formatQ(rest = {}) {
  return Object.keys(rest).reduce((s,o) => ({
    ...s,
    [o]: isNaN(rest[o]) ? rest[o]: (Number(rest[o]) || '')
  }), {});
}
</script>

<style scoped>
.ant-breadcrumb {
  text-align: left;
  display: inline-block;
}
</style>

