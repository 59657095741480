import Vue from 'vue'
import Cookies from 'js-cookie'
const TokenKey = 'MD_TOKEN'

export function getToken() {
  const token = Vue.ls && Vue.ls.get('ACCESS_TOKEN')
  return token;
  //return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
