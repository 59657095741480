<template>
  <div class="box-bar-d">
    <div class="fix-bar-d">
    <p v-for="(item,idx) in menuList" :key="idx"
      :class="item.icon + (item.title==tab ? ' sel' : '')"
       @click="jump(item)"
      >{{item.title}}</p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {getRole} from '@/utils/getRole'

export default {
    props: {
      tab: {
        type: String,
        default: '我的'
      },
    },
    data() {
      return {
        role: getRole(),
        list: [
          {title: '首页', link: 'afDealerIndex', icon: 'm1'},
          {title: '统计分析', link: 'dealerIndex', icon: 'm6'},
          {title: '检核明细', link: 'checkDetailList', icon: 'm4'},
          {title: '试驾明细', link: 'driveList', icon: 'm3'},
          {title: '我的', link: 'member', icon: 'm5'},
        ],
      }
    },
    methods: {
      jump(item) {
        if (item.title === this.tab) {
          return;
        }
        this.$router.push({name: item.link});
      }
    },
    computed: {
      menuList () {
        const userInfo = Vue.ls.get('USER_INFO') || {};
        const l = (userInfo.gzh_sale_menu_arr || []).concat('member');
        const m = this.list.filter(o => (l.indexOf(o.link) >= 0));

        if (this.role.isAfDealerBoth) {
          return [this.list[0], ...m];
        }
        return [...m];
      }
    }
}
</script>
<style lang="less" scope>
.box-bar-d {
  height: 3.5rem;
}
.fix-bar-d {
  width: 100%;
  max-width: 750px;
  position: fixed;
  bottom: 0;
  display: flex;
  background: #fff;
  padding: 0.5rem 0;
  z-index: 1000;
  p {
    font-size: 0.6rem;
    color: #C8C9CC;
    flex: 1;
    text-align: center;
    padding-top: 1.4rem;
    background-size: auto 1.2rem;
    background-position: center top;
    background-repeat: no-repeat;
    margin: 0;

    &.m1 {
      background-image: url('~@/assets/bar_index.png');

      &.sel {
        background-image: url('~@/assets/bar_index_sel.png');
      }
    }
    &.m3 {
      background-image: url('~@/assets/bar_drive.png');
      &.sel {
        background-image: url('~@/assets/bar_drive_sel.png');
      }
    }
    &.m4 {
      background-image: url('~@/assets/bar_check.png');
      &.sel {
        background-image: url('~@/assets/bar_check_sel.png');
      }
    }
    &.m5 {
      background-image: url('~@/assets/bar_my.png');
      &.sel {
        background-image: url('~@/assets/bar_my_sel.png');
      }
    }
    &.m6 {
      background-image: url('~@/assets/bar_count.png');
      &.sel {
        background-image: url('~@/assets/bar_count_sel.png');
      }
    }

  }
}
</style>
