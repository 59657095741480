import { render, staticRenderFns } from "./navbarAdmin.vue?vue&type=template&id=35c7344a&"
import script from "./navbarAdmin.vue?vue&type=script&lang=js&"
export * from "./navbarAdmin.vue?vue&type=script&lang=js&"
import style0 from "./navbarAdmin.vue?vue&type=style&index=0&id=35c7344a&prod&lang=less&scope=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports