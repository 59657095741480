export default {
    RegionHidden: [
        'BoardCountry'
    ],
    AreaHidden: [
        'BoardCountry', 'BoardRegion'
    ],
    storeHidden: [
        'BoardCountry', 'BoardRegion', 'BoardArea'
    ]
}
