import moment from 'moment';

export const dStr = 'YYYY-MM-DD';
export const dtStr = 'YYYY-MM-DD HH:mm:SS';
export const tStr = 'HH:mm:SS';


export function toRange(type) {
  const todayM = moment();
  const yesterday = todayM.clone().add(-1, 'day');
  const lastWeekM = todayM.clone().add(-1, 'week');
  const lastMonthM = todayM.clone().add(-1, 'month');
  const lastThiDay = todayM.clone().add(-30, 'day');
  console.log(todayM)
  return {
    today: [todayM, todayM],
    yesterday: [yesterday, yesterday],
    week: [moment().startOf('week'), todayM],
    lastWeek: [lastWeekM.clone().startOf('week'), lastWeekM.clone().endOf('week')],
    month: [moment().startOf('month'), todayM],
    lastMonth: [lastMonthM.clone().startOf('month'), lastMonthM.clone().endOf('month')],
    lastestThiDay: [lastThiDay.clone().endOf('day'), todayM],
  }[type];
}

export function normalDate(date) {
  if (!date) {
    return;
  }
  if (Object.prototype.toString.call(date) === '[object Array]') {
    return date.map(o => moment(o).format(dStr));
  }
  return moment(date).format(dStr);
}
