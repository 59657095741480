<template>
  <div class="page-reception-ai">
    <div class="sec box-title">
      <strong>AI接待要点及诊断</strong>
      <span style="">【销售引擎大模型】</span>
    </div>
    <h3 class="title">接待要点</h3>
    <div class="sec box-summary">
      <div class="sub-tit">要点总结</div>
      <div class="text">{{aiSummary.summary}}</div>
      <div class="sub-tit">要点</div>
      <div class="text" :key="idx" v-for="(item, idx) in aiSummary.content">
        <h5>{{item.topic}}</h5>
        <ol class="list">
          <li :key="i" v-for="(text, i) in item.points">{{text}}</li>
        </ol>
      </div>
      
      <fragment v-if="(aiScript.focusList || []).length">
      <div class="sub-tit">接待不足</div>
      <div class="text-i">
          <p class="cry" :key="i" v-for="(text, i) in aiScript.focusList">{{text}}</p>
      </div>
      </fragment>
      <fragment v-if="(aiScript.speechSkills || []).length">
      <div class="sub-tit">改善建议（推荐话术）</div>
      <div class="text-i">
          <p class="good" :key="i" v-for="(text, i) in aiScript.speechSkills">{{text.response}}</p>
      </div>
      </fragment>
      
    </div>

    <h3 class="title">AI接待诊断</h3>
    <div class="ai-info sec">
      <div class="item r1">
        <p class="t">AI客户级别</p>
        <p class="v">{{aiEvaluation.user_level}}</p>
      </div>
      <div class="item r2">
        <p class="t">
          <a-tooltip overlayClassName="box-tt-ai">
            AI成交阶段
            <template slot="title">
              <div>
              AI成交阶段：当前客户所处成交意向阶段。<br/><br/>

              成0：没有提到任何成交意向阶段的内容<br/>
              成1：明确需求<br/>
              成2：接受产品<br/>
              成3：认可价值<br/>
              成4：相信交付<br/>
              成5：没有风险<br/>
              已全：5个成交意向阶段都有提到
              </div>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip>
        </p>
        <p class="v">
        <span>{{aiEvaluation.barriers_level}}</span>
        <span v-if="aiEvaluation.deal_advise" style="margin-left: 0.25rem; font-weight: normal">{{aiEvaluation.deal_advise}}</span>
        </p>
      </div>
      <div class="item r3">
        <p class="t">
        <a-tooltip overlayClassName="box-tt-ai">
          AI成交指数
          <template slot="title">
            AI成交指数：购买意向度的模型评分；
          </template>
          <a-icon type="exclamation-circle" />
        </a-tooltip>
        </p>
        <p class="v">{{aiEvaluation.index}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { postForm, fetchPost } from '@/api'
import {getRole} from '@/utils/getRole'

export default {
  data() {
      return {
        curStep: 't0',
        isVolvo: false,
        aiScript: {},
        aiEvaluation: {},
        aiSummary: {},
      }
  },
  methods: {
    onSearch() {
      fetchPost('/h5/seller/getDetailFive', {
        id: this.id,
      }).then(res => {
          if (res.code === 200) {
            const {aiSummary, aiEvaluation, aiScript, speechSkills} = res.data;
            this.aiSummary = aiSummary;
            this.aiEvaluation = aiEvaluation;
            this.aiScript = aiScript;
            this.speechSkills = speechSkills;
            console.log(res);
          } else {
            this.$message.error(res.message);
          }
        });
    }
  },
  components: {
  },
  created() {
    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.onSearch();
  },
}
</script>

<style lang="less">
.box-tt-ai {
  min-width: 14rem;
  white-space: nowrap;
}

.page-reception-ai {
  position: relative;
  padding-bottom: 1rem;

  h3.title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .box-title {
    padding: 0.8rem;
    strong {
      font-size: 1rem;
      color: #0396ff;
    }
    span {
      font-size: 0.7rem;
      margin-left: 0.25rem;
    }
  }

  .sub-tit {
    font-weight:700;
    color:#000;
    font-size:0.7rem;
    padding: 0.5rem 0;
    line-height: 1;
  }
  
  .box-summary {
    padding: 0.5rem 0.8rem;

    .text-i {
      p {
        border-bottom: 1px solid #eee;
        margin-bottom: 0;
        padding: 0.3rem 0 0.3rem 1.25rem;

        &.good {
          background: url(~@/assets/i_good.png) no-repeat 0 center;
          background-size:1rem 1rem;
        }
        &.cry {
          background: url(~@/assets/i_cry.png) no-repeat 0 center;
          background-size:1rem 1rem;
        }
      }
    }

    .text {
      font-size: 0.7rem;
      line-height: 1.6;

      h5 {
        font-weight:400;
        color:#333333;
        font-size:0.7rem;
        margin-bottom: 0;
      }
      ol.list {
        padding-left: 0.8rem;

        li {
          list-style: decimal;
        }
      }
      
    }
  }
  .ai-info {
    padding: 0.8rem;
    .item {
      border-radius:0.5rem;
      padding: 1rem 0.75rem 1rem 3.5rem;
      margin-top: 0.75rem;

      &.r1 {
        margin-top: 0;
        background: #f8f8f8 url(~@/assets/as_ai_user.png) no-repeat 0.75rem center;
        background-size:2.25rem 2.25rem;
      }
      &.r2{
        background: #f8f8f8 url(~@/assets/as_ai_deal.png) no-repeat 0.75rem center;
        background-size:2.25rem 2.25rem;
      }
      &.r3 {
        background: #f8f8f8 url(~@/assets/as_ai_index.png) no-repeat 0.75rem center;
        background-size:2.25rem 2.25rem;
      }

      p {
        margin: 0;
        padding: 0;

        &.t {
          color:#666666;
          font-size:0.7rem;
        }
        &.v {
          font-weight:700;
          color:#333333;
          font-size:0.8rem;
        }
      }
    }
  }
  
}

</style>
