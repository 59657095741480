import Vue from 'vue'
import Vuex from 'vuex'
import { login, logout, postForm } from '@/api'

import { getToken, setToken, removeToken } from '@/dbCommon/utils/auth'
import {  setCookie, removeCookie } from '@/dbCommon/utils/cookie'
import { checkRole,getFormatDate } from '@/dbCommon/utils/index'
import { postData } from "@/dbCommon/api/index";
import {  getData } from '@/api/index';


Vue.use(Vuex)

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: {},
    query:{...getFormatDate('month')}
  }
}
export default new Vuex.Store({
  state:  getDefaultState(),
  mutations: {
    SET_MULTI_COM_QUERY:(state,query)=>{
      state.multi_company_query = query
    },
    SET_USERINFO: (state, info) => {
      state.userInfo = info
    },
    SET_DB_QUERY:(state,query)=>{
      state.queryDB=query
    }
  },
  actions: {
     Authorization({commit},adminId){
       return new Promise(async(resolve,reject)=>{
         try{
          const {data:result}=await postData("/api/admin/loginmf",{adminId});
          result.isReport = true;

          const expireTime = 180 * 24 * 60 * 60 * 1000;
          Vue.ls.set('ACCESS_TOKEN', result.token)
          Vue.ls.set('LOGIN_TYPE', result.login_type)
          Vue.ls.set('SN', result.wifi_badge_name)
          Vue.ls.set('ID', result.id)
          Vue.ls.set('USER_INFO', result)
          Vue.ls.set('multi_company_id', result.default_company_id);
          Vue.ls.remove('BACK_HOME')

          const {token,admin_type_id,name,crop_id_default,company_ids=null, brand_ids, ...rest} = result;
          setCookie('loginType', result.login_type)
          setCookie("isSuper",admin_type_id)
          setCookie("adminId",admin_type_id)
          setCookie("cropId",crop_id_default)
          setCookie("name",name)
          setCookie("company_ids",company_ids)
          setCookie("brand_ids",brand_ids)
          setCookie("brandId", rest.brand_id)
          commit('SET_USERINFO', result)

           /*
           if (result.login_type == 3) {
              postForm('/api/admin/getUserBoardInfo').then(res => {
                if (res.status !== 1) {
                  return;
                }
                Vue.ls.set('BOARD_INFO', res.data);
              });
           }
           */

          resolve(result);
         }catch(e){
           const dom = document.getElementById('app');
           dom.innerText = e;
           dom.style.color= '#000';
           dom.style.textAlign= 'center';
           dom.style.paddingTop= '300px';
           dom.style.fontSize= '16px';
           reject(e)
         }
       })
    },
    
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          const result = response.data || {}
          result.isReport = false;

          const expireTime = 180 * 24 * 60 * 60 * 1000;
          Vue.ls.set('ACCESS_TOKEN', result.token)
          Vue.ls.set('LOGIN_TYPE', result.login_type)
          Vue.ls.set('SN', result.wifi_badge_name)
          Vue.ls.set('ID', result.id)
          Vue.ls.set('USER_INFO', result)
          Vue.ls.set('multi_company_id', result.default_company_id);
          Vue.ls.remove('BACK_HOME')

          const {token,admin_type_id,name,crop_id_default,company_ids=null, brand_ids, ...rest} = result;
          setCookie('loginType', result.login_type)
          setCookie("isSuper",admin_type_id)
          setCookie("adminId",admin_type_id)
          setCookie("cropId",crop_id_default)
          setCookie("name",name)
          setCookie("company_ids",company_ids)
          setCookie("brand_ids",brand_ids)
          setCookie("brandId", rest.brand_id)
          commit('SET_USERINFO', result)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_USERINFO', {})
          Vue.ls.remove('ACCESS_TOKEN')
          Vue.ls.remove('USER_INFO')
          Vue.ls.remove('BACK_HOME')
          removeCookie("isSuper")
          removeCookie("name")
          removeCookie("company_ids")
          
        })
      })
    },
  }
})
