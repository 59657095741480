<template>
  <div class="box-bar-af">
    <div class="fix-bar-af">
    <p v-for="(item,idx) in menuList" :key="idx"
      :class="item.title==tab ? 'sel' : ''"
       @click="jump(item)"
       ><span>{{item.title}}</span></p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
    props: {
      tab: {
        type: String,
        default: '我的'
      },
    },
    data() {
      return {
        list: [
          {title: '服务接待', link: 'afReceptionList'},
          {title: '售后分析', link: 'afCount'},
          {title: '我的', link: 'member'},
        ],
      }
    },
    methods: {
      jump(item) {
        if (item.title === this.tab) {
          return;
        }
        this.$router.push({name: item.link});
      }
    },
    computed: {
      menuList () {
        const userInfo = Vue.ls.get('USER_INFO') || {};
        const l = (userInfo.gzh_sa_menu_arr || []).concat('member');
        const m = this.list.filter(o => (l.indexOf(o.link) >= 0));
        return [...m];
      }
    }
}
</script>
<style lang="less" scope>
.box-bar-af {
  height: 2.5rem;
}
.fix-bar-af {
  width: 100%;
  height: 2.5rem;
  max-width: 750px;
  position: fixed;
  bottom: 0;
  display: flex;
  background: #fff;
  z-index: 1000;
  p {
    font-size: 0.8rem;
    color: #999;
    flex: 1;
    text-align: center;
    margin: 0;
    line-height: 2.5rem;

    &.sel {
      color: #000;
      span {
        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          background: #0070C0;
          width: 100%;
          height: 0.2rem;
          border-radius: 0 0 0.5rem 0.5rem;
        }
      }
    }
  }
}
</style>
