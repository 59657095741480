import Vue from 'vue'
import qs from 'qs'
import {getRole} from '@/utils/getRole'

import {
  postForm,
  getProvinceOtps,
  getCropOtps,
  getBrandOtps,
  getRegionOtps,
  getAreaOtps,
  getCompanyOtps,
  getDepartmentOtps,
  getCityOtpsById,
  getBrandOtpsById,
  getRegionOtpsById,
  getAreaOtpsById,
  getCompanyOtpsById,
  getDepartmentOtpsById,
  getGroupOtpsById,
  getHotTypeOtps,
  getHotLabelOtps,
  getCarModelOtps,
  getEntryStateOtps,
  getDriveOtps,
  snListsList
} from '@/api'



export async function handleProvinceChange (province_id) {
    const cityOtps = await getCityOtpsById({province_id});
    return {
      optList: {cityOtps},
      resetData: {
        city_id: null,
      }
    }
}

export async function handleCropChange (crop_id) {
    if (Array.isArray(crop_id)) {
      crop_id = crop_id.join(',');
    }
    const regionOtps = await getRegionOtpsById({crop_id});
    const brandOtps = await getBrandOtpsById({crop_id});
    return {
      optList: {regionOtps, brandOtps},
      resetData: {
        brand_id: null,
        region_id: null,
        area_id: null,
        company_id: null,
        department_id: null,
        group_id: null
      }
    }
  /*
    const brandOtps = await getBrandOtpsById({crop_id});
    return {
      optList: {brandOtps},
      resetData: {
        brand_id: null,
        region_id: null,
        area_id: null,
        company_id: null,
        department_id: null,
        group_id: null
      }
    }
    */
}

export async function handleBrandChange (brand_id) {
    if (Array.isArray(brand_id)) {
      brand_id = brand_id.join(',');
    }
    const regionOtps = await getRegionOtpsById({brand_id});
    return {
      optList: {regionOtps},
      resetData: {
        region_id: null,
        area_id: null,
        company_id: null,
        department_id: null,
        group_id: null
      }
    }
}

export async function handleRegionChange (region_id) {
    const areaOtps = await getAreaOtpsById({region_id});
    return {
      optList: {areaOtps},
      resetData: {
        area_id: null,
        company_id: null,
        department_id: null,
        group_id: null
      }
    }
}

export async function handleAreaChange (area_id) {
    const companyOtps = await getCompanyOtpsById({area_id});
    return {
      optList: {companyOtps},
      resetData: {
        company_id: null,
        department_id: null,
        group_id: null
      }
    }
}

export async function handleCompanyChange (company_id) {
    if (Array.isArray(company_id)) {
      company_id = company_id.join(',');
    }
    const departmentOtps = await getDepartmentOtpsById({company_id});
    return {
      optList: {departmentOtps},
      resetData: {
        department_id: null,
        group_id: null
      }
    }
}

export async function handleDepartmentChange (department_id) {
    const groupOtps = await getGroupOtpsById({department_id});
    return {
      optList: {groupOtps},
      resetData: {
        group_id: null
      }
    }
}

//门店类型
export function getCompanyTypeList (param={}) {
  return postForm('/api/option/getCompanyTypeList', param).then(res => {
    if (res.status === 1) {
      const list = (res.data || []);
      return list.map(o => formatOption(o.id, o.id, o.name));
    } else {
      return [];
    }
  });
}


export function getOptionList (table, param={}, auth=1) {
  const isSuper = getRole().isSuper;
  if (table == 'brand' && !isSuper) {
    delete param.crop_id
  }
  
  return postForm('/api/option/index', {table, ...param, auth}).then(res => {
    if (res.status === 1) {
      const list = (res.data || []);
      if (table == 'company') {
        return list.map(o => ({
          key: o.id, value: o.id,
          label: o.name, title: o.name,
          code: o.code
        }));
      }
      
      return list.map(o => formatOption(o.id, o.id, o.name));
    } else {
      return [];
    }
  }, res => {
    return [];
  });
}

function formatOption(key, value, label) {
  return {
    key: String(key), value, label, title: label
  }
}
export default {
  getCarModelOtps,
  getHotLabelOtps,
  getHotTypeOtps,
  getOptionList,
  getProvinceOtps,
  getCropOtps,
  getBrandOtps,
  getRegionOtps,
  getAreaOtps,
  getCompanyOtps,
  getDepartmentOtps,
  handleProvinceChange,
  handleCropChange,
  handleBrandChange,
  handleRegionChange,
  handleAreaChange,
  handleCompanyChange,
  handleDepartmentChange,
  snListsList,
  getEntryStateOtps,
  getDriveOtps,
  getCompanyTypeList,
};
